.search-challenges-view {
    max-width: 1000px;
    margin: 30px auto;
}

.filter-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.custom-select {
    background: whitesmoke;
    color: #222840;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    margin: 3px;
    padding: 10px;
    border: none;
    cursor: pointer;
    min-width: 25%;
    outline: none;
}

.search {
    border-radius: 10px;
    outline: none;
    background: whitesmoke;
    color: #222840;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    margin: 3px;
    padding: 10px;
    border: none;
    min-width: 25%;
    outline: none;
}