.user-profile {
    margin: 0 auto;
    padding: 30px 0;
    max-width: 1000px;
    height: 88vh;
    display: flex;
    justify-content: space-around;
}

.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #232840;
    max-width: 30%;
    padding: 30px;
    border-radius: 10px;
    height: 100%;
}

.sidebar-avatar {
    max-width: 100%;
    border-radius: 50%;
    margin-bottom: 20px;
}

.sidebar-tabs {
    cursor: pointer;
}

.sidebar-tab {
    text-align:center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-top: 1px dashed #f1f1f1;
}

.sidebar-tab:hover {
    color: #9ac6c9;
    ;
}

.sidebar-tab:last-of-type {
    border-bottom: 1px dashed #f1f1f1;
}

.sidebar-tab-inactive {
    color: rgb(192, 71, 137);
}

.sidebar-tab-active {
    color: #9ac6c9;
    ;
}

.tabs-view {
    width: 100%;
    margin: 0 5px;
    overflow-y: auto;
    border-radius: 10px;
}

.user-info * {
    margin: 15px 0;
}

.tabs-right-side {
    background: #232840;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    border-radius: 10px;
}

.user-info-username {
    color: #9ac6c9;
    font-size: 4rem;
    font-weight: bold;
}

.user-info-joined {
    font-size: 2rem;
    font-weight: lighter;
}

.user-info-experience {
    color: #c04789;
    font-size: 4rem;
    font-weight: bold;
}

.logout-button {
    border-radius: 5px;
    border: 1px solid #c04789;
    outline: none;
    cursor: pointer;
    background: #222840;
    margin-top: 15px;
    padding: 14px 30px;
    font-weight: bold;
    font-size: 1.3rem;
    color: whitesmoke;
}

.logout-button:hover {
    background: #c04789;
}

.preparedness-tab {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.preparedness-toggle .preparedness-btn {
    border-radius: 5px;
    border: 1px solid #c04789;
    outline: none;
    cursor: pointer;
    background: #222840;
    margin: 15px;
    padding: 14px 30px;
    font-weight: bold;
    font-size: 1.3rem;
    color: whitesmoke;
}

.preparedness-toggle .preparedness-btn__active {
    border: 1px solid #c04789;
    background: #c04789;
    color: whitesmoke;
}

.preparedness-table {
    height: 100%;
    width: 100%;
    text-align: center;
}

.preparedness-row {
    margin: 15px;
}

.preparedness-header {
    font-size: 1.5rem;
    color: #c04789;
    font-weight: bold;
    margin-bottom: 0;
}

.preparedness-body {
    display: flex;
    flex-direction: column;
}

.preparedness-column {
    display: inline-block;
}

.preparedness-column-big {
    font-size: 1.4rem;
    width: 40%;
}

.preparedness-column-small {
    width: 20%;
    font-weight: bold;
    font-size: 1.3rem;
}