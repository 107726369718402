.create-challenge-container {
  width: 1000px;
  margin: 50px auto;
  padding-bottom: 10px;
  height: auto;
}

.meta-container{
    width: 700px;
    margin: 50px auto;
}

.meta-form{
    display: flex;
    justify-content: space-between;
}

.description-editor-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.markdown-render{
    width: 60%;
    height: 300px;
    background-color: #1C2938;
    margin: auto;
    box-sizing: border-box;
    border: solid gray 5px;
    border-radius: 5px;
    color: white;
    padding: 10px;
}

.hide-button{
    display: none;
}

.show-button{
    display: inline;
}

.challenge-code-container{
    display: flex;
    justify-content: center;
}

.editor {
  width: 60%;
  border-radius: 5px;
}

.description-editor {
  height: 270px;
}

.playground {
  background-color: #1E1E2C;
}

.code-editor {
  position: relative;
  width: 100%;
}

.editor-header {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 10px;
  color: #fafafa;
}

.editor-header h2 {
  font-size: 18px;
}

.react-codemirror2 {
  max-height: 100%;
  width: 100%;
  margin-top: 0;
}

.result {
  left: 600px;
  overflow: hidden;
  background-color: lightgray;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.tests {
  margin: 0 5px;
  background-color: red;
}

.create-challenge-tests{
    margin: 0px auto;
}

.console-div{
    background-color: #222840;
    margin-left: 250px;
    color: white;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    color: white;
    width: 900px;
    height: 350px;
    margin: 0 auto 30px;
    overflow: auto;
}

.console-div pre {
  margin: 10px 0px;
}

.console-div::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

/* Handle */
.console-div::-webkit-scrollbar-thumb {
  background: #9AC6C9;
  border-radius: 5px;
}

/* Handle on hover */
.console-div::-webkit-scrollbar-thumb:hover {
  background: #9ac6c9;;
}

.console-div p{
    margin: 0;
}

.tabs {
  width: 900px;
  margin: 30px auto;
  height: 430px;
}

.tab-list {
  padding-left: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  width: 180px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  height: 50px;
  padding-top: 13px;
  border-bottom: 2px solid #ccc;
}

.tab-list-active {
  border-bottom: 3px solid #C04789;
}

.tab-container {
  padding: 20px;
  background-color: #222840;
  height: 350px;
  margin-bottom: 100px;
  border-radius: 10px;
  overflow: auto;
}

/* width */
.tab-container::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

/* Track */
/* .tab-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
} */

/* Handle */
.tab-container::-webkit-scrollbar-thumb {
  background: #9AC6C9; 
  border-radius: 5px;

}

/* Handle on hover */
.tab-container::-webkit-scrollbar-thumb:hover {
  background: #9ac6c9;; 
}

.challenge-info{
  position: relative;
  height: 25px;
  border: none;
  padding: 3px;
  border-radius: 5px;
  font-weight: bold;
  color: gray;
  width: 180px;
}

.challenge-info:focus {
  outline: none;
}

.test-container {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.test-columns {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.test-input-container{
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-test-button{
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #C04789;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.25rem;
  color: white;
}

.delete-test-button:disabled {
  opacity: 0.6;
}

.delete-test-button:focus {
  outline: none;
}

.test-main-heading {
  margin-bottom: 15px;
}

.heading-input-container{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
}

.tests-input-heading {
  width: 30%;
  text-align: right;
  padding-right: 10px;
}

.text-input-container {
  width: 70%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: white;
  height: 35px;
}

.tests-input{
  width: 100%;
  border: none;
  border-radius: 5px;
  height: 35px;
  padding: 3px;
  margin: 0px;
  font-weight: bold;
  color: gray;
}

.tests-input:focus {
  outline: none;
}

.add-test-button-container {
  margin-top: 20px;
  text-align: center;
}

.add-test-button{
  border: none;
  border-radius: 5px;
  outline: none;
  width: 130px;
  height: 40px;
  font-weight: bold;
  background-color: #9ac6c9;;
  cursor: pointer;
  font-size: 15px;
  color: white;
}

.add-test-button:disabled {
  opacity: 0.5;
}

/* .submit-button-wrapper {
  margin: auto;
  width: 400px;
} */

.run-tests {
  border: none;
  background-color: #9ac6c9;;
  width: 150px;
  height: 50px;
  display: block;
  margin-bottom: 40px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.run-tests:focus {
  outline: none;
}

.run-tests:disabled {
  opacity: 0.6;
  cursor: default;
}

.submit-button {
  width: 50%;
  height: 80px;
  border: none;
  border-radius: 10px;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  background: #C04789;
  color: white;
}

.submit-button:focus {
  outline: none;
}

/*************** categories dropdown styles *****************/
.multi-select {
  height: 25px;
  border-radius: 5px;
}

.dropdown {
  width: 200px !important;
  border: red 3px solid;
  height: 25px;
  font-size: 13px;
  border-radius: 5px;
  border: none !important;
}

.dropdown-heading {
  border: red 3px solid;
  height: 25px !important;
  box-sizing: border-box;
  border: none !important;
  width: 200px !important;
}

.dropdown-heading-value span {
  display: none !important;
  position: relative;
  height: 20px;
  display: inline-block;
  font-weight: bold;
}

.dropdown-content {
  height: 150px !important;
}

.dropdown-content::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background: #9AC6C9; 
  border-radius: 5px;

}

.dropdown-content::-webkit-scrollbar-thumb:hover {
  background: #9ac6c9;; 
}

.select-panel div {
  height: 28px;
  padding: 3px !important;
}

.select-panel input {
  height: 25px !important;
  padding: 5px !important;
  margin-bottom: -20px;
}

.select-item {
  height: 30px;
  padding: 0px 10px !important;
  margin-top: 5px;
  /* display: none; */
}

.select-panel label span:nth-of-type(3) { 
  display: none;
}

.item-renderer {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 19px;
}

.item-renderer input {
  height: 13px !important;
  margin: 0;
  margin-right: 7px;
  margin-top: 4px;
}

.item-renderer span {
  padding: 0px 5px !important;
}

/* .select-list {
  margin-top: -35px !important;
  background-blend-mode: lighten;
} */

.submit-button-container{
  width: 600px;
  text-align: center;
  /* margin: 60px auto; */
}

.submit-button-container h1{
  font-size: 50px;
  margin-bottom: 20px;
  /* color: #C04789; */
}

.test-button-container{
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none;
}

.tab-container-submit{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* styling for modal */
.create-challenge-modal {
  margin: 120px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #343D61;
  width: 400px;
  height: 300px;
  padding: 30px;
  border-radius: 5px;
}

.create-challenge-modal:focus {
  outline: none;
}

.modal-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 250px;
  color: white;
  text-align: center;
}

.modal-text-container p {
  font-weight: normal;
  margin: 10px auto 0;
}

.modal-text-container button {
  border: none;
  width: 100px;
  height: 30px;
  margin-top: 20px;
  border-radius: 5px;
  color: #343D61;
  font-weight: bold;
  cursor: pointer;
}

/* help tips */
.help-tip{
    top: 18px;
    right: 18px;
    text-align: center;
    border: 1px solid white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: default;    
    margin: 0px 5px;
}

.help-tip-dark{
  background: darkgray;
  width: 17px;
  height: 17px;
  font-size: 13px;
}

.help-tip:before{
    content:'?';
    color:white;
}

.help-tip-dark:before{
  content:'?';
  color:white;
}

.help-tip:hover p {
    position: relative;
    right: 220px;
    display:block;
    transform-origin: 100% 0%;

    -webkit-animation: fadeIn 0.3s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;
    z-index: 20;
}

.help-tip p{    /* The tooltip */
    display: none;
    text-align: left;
    background-color: #9ac6c9;
    padding: 20px;
    width: 250px;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    color: #FFF;
    font-size: 13px;
    line-height: 1.4;
    opacity: 1;
}

.help-tip p:before{ /*The pointer of the tooltip */
    position: absolute;
    content: '';
    width:0;
    height: 0;
    border:6px solid transparent;
    border-bottom-color:#9ac6c9;
    right:15px; 
    top: -12px;
  }

.help-tip p:after{ /* Prevents the tooltip from being hidden */
    width:100%;
    height:40px;
    content:'';
    top:-40px;
    left:0;
}

/* CSS animation */

@-webkit-keyframes fadeIn {
    0% { 
        opacity:0; 
        transform: scale(0.6);
    }

    100% {
        opacity:100%;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:100%; }
}