.console {
    padding: 10px;
    background: #222840;
    border-radius: 10px;
    color: white;
    width: 900px;
    height: 350px;
    margin: 0 auto 80px;
    overflow: auto;
}

.attempt-challenge-console {
    padding: 10px;
    background: #222840;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
    width: 100%;
    height: 350px;
    margin: 0px auto;
    overflow: auto;
}

.fullscreen-console {
    padding: 10px;
    background: #222840;
    border-radius: 10px;
    color: white;
    width: 100%;
    height: 46vh;
    overflow: auto;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.console-buttons-wrapper {
    width: 100%; 
    border-bottom: 3px solid #9ac6c9;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.console-button {
    cursor: pointer;
    outline: none;
    border: 1px solid #9ac6c9;;
    border-radius: 5px;
    margin: 10px 30px;
    background: #222840; 
    color: #9ac6c9;;
    font-weight: bold;
    padding: 7px;
}

.console-button:disabled {
    opacity: 0.5;
    cursor: default;
}

.console-button:hover:enabled {
    border-radius: 5px;
    background: #9ac6c9;;
    color: #222840;
}