.header-wrapper {
  background: #222840;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 7vh;
}

.header {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo,
.logo:visited,
.logo:active {
  color: #9ac6c9;
  font-size: 1.5rem;
  text-decoration: none;
  margin-right: 30px;
  font-weight: bold;
}

.navigation-link,
.navigation-link:visited,
.navigation-link:active {
  font-weight: normal;
  color: #c04789;
  margin-right: 20px;
}

.navigation-link:hover {
  color: whitesmoke;
  font-weight: bold;
}

.account-button {
  border-radius: 5px;
  border: 1px solid #c04789;
  outline: none;
  cursor: pointer;
  background:  #222840;
  padding: 7px 15px;
  font-weight: bold;
  font-size: .8rem;
  color: whitesmoke;
}

.account-button:hover {
  background:  #c04789;
}