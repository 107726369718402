.landing-container {
  background-color: #232840;
  max-width: 1000px;
  margin: 0 auto;
  border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 6px 14px 49px -15px rgba(0,0,0,0.75);
  -moz-box-shadow: 6px 14px 49px -15px rgba(0,0,0,0.75);
  box-shadow: 6px 14px 49px -15px rgba(0,0,0,0.75);
  margin-top: 50px;
}

@media (max-width: 600px) {
  .landing-container {
    margin-top: 0;
  }
}

.landing-page__start {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.landing-page__start-btn {
  position: fixed;
  top: 0;
  min-width: 1000px;
  margin-bottom: 50px;
  border-radius: 5px;
  opacity: 0;
  transition: all ease-out .3s;
}

.landing-page__start-btn span {
  display: inline-block;
  background:  #c04789;
  padding: 15px 20px 15px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: whitesmoke;
  cursor: pointer;
  border: 1px solid whitesmoke;
  border-radius: 5px;

}

.landing-page__start-btn span:hover{
  background: #f5f5f6;
  color: #c04789;
}

.z-index {
  z-index: -10;
}

.show {
  opacity: 1;
  z-index: 10;
}

.top-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}

@media (max-width: 600px) {
  .top-content {
    text-align: center;
    flex-direction: column;
  }
}

.top-content h1 {
  color: #c04789;
  font-size: 2.2rem;
  font-weight: bold;
}

.top-content h2 {
  font-size: 1.5rem;
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: bold;
}

.top-content p {
  color: #f5f5f6;
  font-size: 1.2rem;
  line-height: 1.5;
}

.top-content button {
  padding: 20px 20px;
  border-radius: 5px;
  border: none;
  background: #c04789;
  color: #f5f5f6;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid whitesmoke;
  outline: none;

}
.top-content button:hover{
  background: #f5f5f6;
  color: #c04789;
}

.top-content-left {
  width: 50%;
}

@media (max-width: 600px) {
  .top-content-left {
    width: 100%;
  }
}

.content-cards {
  background: #f5f5f6;
  color: #000019;
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 40px 20px;
}

@media (max-width: 600px) {
  .card {
    padding: 20px;
    flex-direction: column-reverse;
  }

  .card:nth-child(2) {
    flex-direction: column;
  }
}

.card div {
  padding: 0 20px;
  text-align: center;
}

.card p {
  font-size: 1.2rem;
  line-height: 1.5;
}

.card h2 {
  color: #c04789;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-weight: bold;
}

.card img {
  width: 50%;
  height: auto;
}

@media (max-width: 600px) {
  .card img {
    width: 100%;
    margin-bottom: 10px;
  }
}

.testimonials {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin: 40px 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

@media (max-width: 600px) {
  .testimonials {
    flex-direction: column;
    padding: 0 20px;
  }
}

.testimonials-header {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-top: 50px;
}

.testimonial-card {
  width: 30%;
  background: #f5f5f6;
  border-radius: 10px;
  -webkit-box-shadow: 6px 14px 49px -15px rgba(0,0,0,0.75);
  -moz-box-shadow: 6px 14px 49px -15px rgba(0,0,0,0.75);
  box-shadow: 6px 14px 49px -15px rgba(0,0,0,0.75);
  color: #000019;
  margin-bottom: 30px;
}

@media (max-width: 600px) {
  .testimonial-card {
    width: 100%;
    margin-bottom: 50px;
  }
}

.testimonial-card div {
  text-align: center;
}

.testimonial-card img {
  width: 200px;
  height: auto;
  border-radius: 50%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.testimonial-card h3 {
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
}

.testimonial-card p {
  width: 90%;
  margin: 0px auto 30px auto;
  text-align: justify;
  border-top: 2px solid #9ac6c9;
  padding-top: 30px;
  line-height: normal;
}

.card-sm {
  min-height: 450px;
}

.about-cards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
  margin-bottom: 70px;
}

@media (max-width: 600px) {
  .about-cards {
    flex-direction: column;
  }
}

.about-card {
  /* flex-grow: 1;
  flex: 1 1 30%; */
  width: 30%;
  text-align: center;
  background: #9ac6c9;
  margin-bottom: 50px;
  padding: 30px 20px 20px 20px;
  border-radius: 5px;
  -webkit-box-shadow: 3px 5px 32px -12px rgba(0,0,0,0.75);
  -moz-box-shadow: 3px 5px 32px -12px rgba(0,0,0,0.75);
  box-shadow: 3px 5px 32px -12px rgba(0,0,0,0.75);
}

@media (max-width: 600px) {
  .about-card {
    width: 100%;
  }
}

.about-card__img {
  border-radius: 50%;
  width: 150px;
  height: auto;
}

.about-card__heading {
  text-align: center;
  text-transform: uppercase;
  color: #c04789;
  margin-bottom: 40px;
  padding-top: 50px;
}

.about-card__name {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid #8ab2b4;
  color: #263238;
  font-weight: 600;
}

.about-card__title {
  font-weight: 400;
  color: #4d6364;
}

.about-card__link {
  color: #4d6364;
  text-decoration: none;
  font-weight: 500;
}

.about-card__i {
  font-size: 2.2rem;
  color: #1b1f23;
}