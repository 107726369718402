.challenge-wrapper {
    background: #232840;
    cursor: pointer;
    margin: 0 auto 30px;
    border-top: 1px dashed rgba(0, 0, 0, 0.4);
    border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 15px;
    transition: all .2s ease-in-out;
}

.challenge-wrapper:hover {
    box-shadow: 5px 5px #9ac6c9;
}

.card-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #343d61;
    padding-bottom: 5px;
}

.challenge-title {
    font-size: 1.7rem;
    margin-bottom: 15px;
}

.pill-button {
    font-size: 1rem;
    border: none;
    background: none;
    color: whitesmoke;
    background: #c04789;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 15px;
}

.right-header {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #c04789;
    margin-right: 10px;
}

.popularity {
    display: flex;
    align-items: flex-end;
    margin-left: 5px;
}

.popularity-num {
    margin-right: 10px;
    font-weight: lighter;
}

.thumbs-up {
    margin-bottom: 2px;
}

.difficulty {
    color: #c04789;
    font-weight: lighter;
}

.card-body {
    display: flex;
    justify-content: space-between;
}

.card-footer {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.approve-challenge-button {
    border-radius: 5px;
    border: 1px solid #c04789;
    outline: none;
    cursor: pointer;
    background: #222840;
    margin-top: 15px;
    padding: 7px 15px;
    font-weight: bold;
    font-size: .8rem;
    color: whitesmoke;
}

.challenge-description {
    height: 120px;
    overflow: hidden;
    color: #9ac6c9;

}

.challenge-description__open {
    overflow: visible;
    min-height: 164px;
}

.challenge-description__btn {
    cursor: pointer;
    outline: none;
    border: none;
    margin-top: 15px;
    background: #222840;
    color: #9ac6c9;
    border: 1px solid #9ac6c9;
    border-radius: 5px;
    font-weight: bold;
    padding: 7px;
}

.challenge-description__btn:hover {
    color: #222840;
    background: #9ac6c9;
    ;
}