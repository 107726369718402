.challenge-header-wrapper {
    display:flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 10px 0 0;
    padding: 5px 0;
}

.fullscreen-challenge-header-wrapper {
    display:flex;
    background:#343d61;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 5px 10px;
    height:5vh;
}

.challenge-header {
    font-size: 1.5rem;
    margin: 0px 15px 0px 0px;
}

.categories {
    background: #343d61;
    color: #c04789;
    margin: 0px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight:bold;
}

.attempt-challenge-wrapper {
    display: flex ;
    flex-direction:column;
}

.markdown-wrapper {
    background: #263238;
    color: white ;
    padding: 10px;
    width: 45%; 
    height: 300px ;
    overflow: scroll;
    flex-wrap: wrap;
}

.top-panel {
    width:100%;
    display:flex;
    justify-content: space-between;
}

.unnecessary-div {
    width:50%;
    border-radius:10px;
}

.fullscreen-unnecessary-div {
    width:60%;
    height:50%;
    border-radius:10px;
}

.challenge-instructions {
    color: #1C2938;
    margin-top: 10px;
    margin-bottom: 0px;
    padding-bottom: 3px;
    border-bottom: 1px solid #1C2938;
}

.editor-wrapper {
    background: #232840;
}

.attempt-markdown-wrapper {
    width: 50%;
    height: 396px;
    background-color: #f7f7f7;
    border-left: 5px solid #343d61;
    margin: auto;
    box-sizing: border-box;
    color: #1C2938;
    padding: 10px;
    overflow: scroll;
}

.fullscreen-attempt-markdown-wrapper {
    width: 40%;
    height: 49vh;
    background-color: #f7f7f7;
    border-left: 5px solid #343d61;
    color: black;
    padding: 10px;
    overflow: scroll;
}

.editor-style {
    height: 350px;
}

.fullscreen-editor-style{
    height: 44vh;
}

.action-bar {
    padding:5px 10px;
    background: #222840;
    display:flex;
    justify-content:space-between;
    align-items:center;
}

.fullscreen-action-bar{
    padding:5px 10px;
    background: #222840;
    display:flex;
    justify-content:space-between;
    align-items:center;
    height:5vh;
}

.icon-wrapper {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.challenge-sub-header-wrapper {
    width: 50%;
    display: flex;
}

.xp-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.xp-points {
    margin-left: 5px;
}