/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #343d61;
  color: #9ac6c9;
}

* {
  box-sizing: border-box;
}

.react-codemirror2 {
  max-height: 100%;
  width:430px;
  margin-top:20px;
}

.result {
  left: 600px;
  overflow: hidden;
  background-color: lightgray;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.tests {
  margin: 0 5px;
  background-color: red;
}

/* .MuiButtonBase-root {
  padding:0px;
} */

pre {
  overflow-y: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code {
  font-family: monospace;
}

p {
  display: block;
}

h1 {
  display: block;
  font-size: 2em;
  font-weight: bold
}
 
h2 {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  font-weight: bold;
}

h4 {
  display: block;
  font-size: 1em;
  font-weight: bold
}

h5 {
  display: block;
  font-size: .83em;
  font-weight: bold
}

h6 {
  display: block;
  font-size: .67em;
  font-weight: bold
}

ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1__qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 40px
}
ol {
  display: block;
  list-style-type: decimal;
  -webkit-margin-before: 1__qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 40px
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
ul ul, ol ul {
  list-style-type: circle
}
ol ol ul, ol ul ul, ul ol ul, ul ul ul {
  list-style-type: square
}

a,
a:visited,
a:active {
  color: #c04789;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}

a:hover {
  color: #9ac6c9;;
}

/* width */
*::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #9AC6C9;
  border-radius: 5px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #9ac6c9;
}

.mobile-device-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: #343d61;
}

.mobile-device-modal-text {
  text-align: center;
  margin: 10px;
  color: #f1f1f1;
}

.mobile-device-modal-text h1 {
  color: #c04789;
}

.mobile-device-modal-text h3 {
  font-size: 1.5rem;
  color:  #9ac6c9;
}

.mobile-device-modal-text div {
  color: #c04789;
}