.leaderboard-container {
    margin: 0 auto;
}

.leaderboard-content {
    background-color: #232840;
    margin-top: 50px;
    border-radius: 10px;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-bottom: 50px;
}

.leaderboard-header {
    display: flex;
    width: 80%;
    margin: 0px auto 40px auto;
    justify-content: center;
    align-items:baseline;
}

.leaderboard-header h3 {
    color: whitesmoke;
    text-transform: uppercase;
    font-size: 2.5rem;
}

.stars-left {
    margin-right: 20px;
}

.stars-right {
    margin-left: 20px;
}

.star {
    color: 	#FFD700;
    font-size: 2rem;
}

.star__middle {
    font-size: 2.5rem;
    margin-right: 5px;
    margin-left: 5px;
}

.leaderboard-ranks {
    width: 80%;
    margin: 0 auto;
    border-radius: 10px;
    color: #222840;
    -webkit-box-shadow: 6px 14px 49px -15px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 14px 49px -15px rgba(0,0,0,0.75);
    box-shadow: 6px 14px 49px -15px rgba(0,0,0,0.75);
}

.first-place{
    background-color: #c04789;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
}

.second-place {
    background-color: #cc6ba0;
    padding: 20px;
    display: flex;
    display: flex;
    justify-content: space-between;
}

.third-place {
    background-color: #d990b8;
    padding: 20px;
    display: flex;
    display: flex;
    justify-content: space-between;
}

.rank-card {
    background-color: #f5f5f6;
    padding: 20px;
    display: flex;
    display: flex;
    justify-content: space-between;
}

.rank-card:not(:last-child) {
    border-bottom: 1px dashed #343d61;
}

.rank-card:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.first-cup {
    color: whitesmoke;
    font-size: 1.6rem;
    opacity: .8;
}

.second-cup {
    color: whitesmoke;
    font-size: 1.6rem;
    opacity: .7;
}

.third-cup {
    color: whitesmoke;
    font-size: 1.6rem;
    opacity: .5;
}

.rank-card__avatar {
    width: 85px;
    height: auto;
    border-radius: 50%;
}

.rank-card__left {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-around;
    text-align: center;
}

.rank-card__right {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-around;
    text-align: center;
}

.leaderboard-subheading {
    display: flex;
    width: 75%;
    margin: 0 auto;
    justify-content: space-between;
}

.rank-card__column1 {
    width: 10%;
    font-size: 1.2rem;
}

.rank-card__column2 {
    width: 20%;
}

.rank-card__column3 {
    width: 30%;
    font-size: 1.25rem;
}

.rank-card__column4 {
    width: 50%;
    text-align: center;
}

.rank-card__column5 {
  width: 50%;
  font-size: 1.2rem;
}

.leaderboard-table__heading {
    color: whitesmoke;
    padding: 10px 20px;
    font-size: 1.1rem;
    background: #d990b8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
}

.leaderboard-table__heading1 {
    display: inline-block;
    text-align: center;
    width: 12%;
}

.leaderboard-table__heading2 {
    display: inline-block;
    text-align: right;
    width: 30%;
}

.leaderboard-table__heading3 {
    display: inline-block;
    text-align: right;
    width: 22%;
}