.text-shadow-pulse-off {
    color: palevioletred;
    font-weight: bold;
}

.test-passed {
    color:  #9ac6c9;
    font-weight: bold;
}

.test-failed {
    color: #c04789;
    font-weight: bold;
}

.icon-shake{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    animation-delay: 0.1s;
    transform: translate3d(0, 0, 0);
}
  
@keyframes shake {
10%, 90% {
    transform: translate3d(-1px, 0, 0);
}

20%, 80% {
    transform: translate3d(2px, 0, 0);
}

30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
}

40%, 60% {
    transform: translate3d(4px, 0, 0);
}
}